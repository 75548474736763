<template>
  <v-row>
    <v-col>
      <v-btn onclick="history.back()" icon>
           <v-icon large >fi fi-rr-arrow-small-left</v-icon>
      </v-btn>
    </v-col>
  </v-row> 
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>